// ==============================
// Layout overrides
// ==============================

// Change hr icon only
hr {
  &::before {
    content: svg-load('../images/icons/hr-carco.svg', fill = $cis-orange);
  }
}
