// ==============================
// Footer overrides
// ==============================

footer {
  text-align: center;
}

.region-footer {
  > * {
    margin-bottom: 2.1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
