// ==============================
// Header overrides for Carco
// ==============================

.logo {
  max-width: 9em;
}

.menu-level-2 {
  border-top-color: $denim;
}

.header-menu-main {
  .item-level-1 {
    > a,
    > .nolink {
      &:not(.button) { // Don't apply this stuff to buttons
        &::before {
          background: $denim;
        }
      }
    }
  }
}
