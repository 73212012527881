// ==============================
// Home overrides
// ==============================

// ==============================
// Carousel section
// ==============================

.carousel-home {
  .content {
    &::before {
      content: '';
      display: block;
      width: 1.6em;
      height: 1.6em;
      margin: 0 auto 1em;
      background: svg-load('../images/icons/did-you-know.svg', fill = $cis-blue) no-repeat center;
    }
  }
}
